<template lang="pug">
  .chips.chips--inline

    v-chip(color='primary' close close-icon="mdi-close"
      v-if="model.filters.examLocation"
      @click:close="removeFilter({field: 'examLocation'})"
    ).chip-common Exam location: {{ model.filters.examLocation }}
    v-chip(color='primary' close close-icon="mdi-close"
      v-if="model.filters.examDateAfter"
      @click:close="removeFilter({field: 'examDateAfter'})"
    ).chip-common Exam date from: {{ model.filters.examDateAfter | formattedDate }}  
    v-chip(color='primary' close close-icon="mdi-close"
      v-if="model.filters.examDateBefore"
      @click:close="removeFilter({field: 'examDateBefore'})"
    ).chip-common Exam date to: {{ model.filters.examDateBefore | formattedDate }}
    v-chip(color='primary' close close-icon="mdi-close"
      v-if="model.filters.cbr_user.length"
      @click:close="removeFilterMultiple({field: 'cbr_user'})"
    ).chip-common CBR Users: {{ CBRUsers | normalizeListWithComma }}
</template>

<script>
import {mapGetters} from "vuex"
export default {
  props: {
    model: {
      type: Object,
      required: true
    },
    activeCourse: Object
  },
  
  methods: {
    removeFilter({field}) {
      this.$emit('remove', field)
    },

    removeFilterMultiple({field}) {
      this.$emit('remove:multiple', field)
    },
  },

  computed: {
    ...mapGetters({
      getCBRUserBy: 'cbrUser/getNameByID',
      cities: 'crmCourseCities/items'
    }),
    CBRUsers() {
      if(!this.model.filters.cbr_user) return []
      return this.model.filters.cbr_user.map(userID => this.getCBRUserBy(userID))
    },
  }
}
</script>

<style lang="scss">
  .chips--inline {
    display: inline;
  }
  .chip-common {
    margin-top: 0;
    margin-bottom: 16px;
    font-weight: bold;
    &:not(:last-child) {
      margin-right: 8px;
    }

    &--small {
      max-width: 250px;
    }
  }

</style>
